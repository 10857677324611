"use strict";

export function initInScope ($scope) {
    //console.log('affix');
    let header = $(".js-affix-header");

    $(window).scroll(function() {
        let scroll = $(window).scrollTop();

        if (scroll >= 70) {
            header.addClass('is-affix');
        } else {
            header.removeClass('is-affix');
        }
    });
}
