"use strict";

export function initInScope($scope) {

    //console.log('availability-form');

    var availabilityForms = [];
    var availabilityInfoBars = [];
    var availabilityDateViews = [];

    function AvailabilityForm ($element) {
        var _this = this;
        this.$form = $element;
        this.$inputs = $element.find('input, select');
        this.$submitBtn = $element.find('.js-availability-form__submit');
        this.$showOnSubmitElements = $('.js-availability-form__show-on-submit');
        this.initialState = this.$inputs.serializeArray();
        this.$filterForm = $('.js-filter-form');
        this.filterFormObj = this.$filterForm.data('filter-form');

        $element.data('availability-form', this);
        $element.trigger('initialized');

        if (!this.filterFormObj) {
            this.$filterForm.on('initialized', function () {
                /*if the filterform isn't initialized, wait for it*/
                _this.filterFormObj = _this.$filterForm.data('filter-form');

                if ($element.is('.js-availability-form--is-prefiltered')) {
                    var timeout = setTimeout(function () {
                        /*wait for all handlers to be added*/
                        _this.submitForm();
                        clearTimeout(timeout);
                    }, 0);
                }
            });
        }

        this.$form.on('submit', function (evt) {
            if (_this.$filterForm && _this.$filterForm.length) {
                evt.preventDefault();
                _this.submitForm();
            } else {
                var isValid = _this.submitForm();

                if (!isValid) {
                    evt.preventDefault();
                }
            }
        });

        this.$form.find('.js-availability-form__submit').on('click', function (evt) {
            if (_this.$filterForm && _this.$filterForm.length) {
                evt.preventDefault();
                _this.submitForm();
            } else {
                var isValid = _this.submitForm();

                if (!isValid) {
                    evt.preventDefault();
                }
            }
        });

        if ($element.is('.js-availability-form--is-prefiltered')) {
            this.$submitBtn.text(this.$submitBtn.data('submit-again-label'));
            this.addInputsChangeListener();

            if (this.filterFormObj) {
                var timeout = setTimeout(function () {
                    /*wait for all handlers to be added*/
                    _this.submitForm();
                    clearTimeout(timeout);
                }, 0);

            }
        }

        History.Adapter.bind(window,'statechange',function() {
            var timeOut = setTimeout(function () {
                /*  timeout to delay the setting of the filter (filter is set after the browser scrolls to the previous
                 scroll position), otherwise the user doesn't see which filter was changed*/
                var state = History.getState().data;
                var isInitialState = false;

                if (!state || $.isEmptyObject(state)) {
                    /* if there is no state there are no more pages to navigate to.
                     * in this case the initial state has to be used again*/
                    state = _this.initialState;
                } else {
                    var currentIndex = History.getCurrentIndex();
                    var isRealPushSateEvent = (History.getState().data._index != (currentIndex - 1));
                    if (!isRealPushSateEvent) {
                        /* workaround  for: history.js fires an event on pushState
                         * (https://github.com/browserstate/history.js/issues/96) */
                        return;
                    }

                    state = state.formState;
                }

                var historyStateObj = app.helpers.convertSerializedArrayToObject(state);
                var initialStateObj = app.helpers.convertSerializedArrayToObject(_this.initialState);

                var stateObj = $.extend($.extend({}, initialStateObj), historyStateObj);

                _this.$inputs.each(function () {
                    var ele = this;

                    if (ele.name) {
                        if (stateObj[ele.name]) {
                            $(ele).val(stateObj[ele.name]);
                            $(ele).trigger('programmaticChange');
                            return;
                        }

                        $(ele).val(null);
                        $(ele).trigger('programmaticChange');
                    }
                });

                _this.$submitBtn.addClass('hidden');
                _this.$showOnSubmitElements.removeClass('hidden');

                /*disable the info bars if the availability was not set in this state*/
                _this.updateInfoBars(!historyStateObj['availabilityCheck']);
                _this.$form.trigger('AVAILABILITYFORMCHANGE', app.helpers.convertSerializedArrayToObject(_this.$inputs.serializeArray()));

                clearTimeout(timeOut);
            }, 100);
        });

        if (matchMedia('(max-width: 767px)').matches) {
            $element.find('.js-availability-form__xs-submit').on('tap', function (evt) {
                evt.preventDefault();
                var isValid = _this.submitForm();
                if (isValid) {
                    $($(this).data('target')).collapse('hide');
                    _this.updateInfoBars();
                }
            }).on('click', function (evt) {
                evt.preventDefault();
            });
        }
    }

    AvailabilityForm.prototype.submitForm = function () {
        var isValid = true;

        this.$inputs.each(function () {
            if ($(this).parsley(app.modules.parsley.options).validate() !== true) {
                isValid = false;
            }
        });

        if (isValid) {
            this.$submitBtn.addClass('hidden');
            this.$showOnSubmitElements.removeClass('hidden');
            if (this.$submitBtn.data('submit-again-label')) {
                this.$submitBtn.text(this.$submitBtn.data('submit-again-label'));
            }
            this.addInputsChangeListener();
            this.$form.trigger('AVAILABILITYFORMCHANGE', app.helpers.convertSerializedArrayToObject(this.$inputs.serializeArray()));

            if (this.filterFormObj) {
                this.filterFormObj.setAdditionalParams(this.$inputs.serializeArray());
                this.$filterForm.submit();
            }
        }

        try {
            localStorage.setItem('availabilibty-form-state', JSON.stringify(app.helpers.convertSerializedArrayToObject(this.$inputs.serializeArray())));
        } catch (e) {}

        return isValid;
    };

    AvailabilityForm.prototype.addInputsChangeListener = function () {
        var _this = this;

        this.$inputs.on('change', function (evt) {
            evt.preventDefault();

            if (_this.$submitBtn.data('submit-again-label')) {
                _this.$submitBtn.text(_this.$submitBtn.data('submit-again-label'));
            }
            _this.$submitBtn.removeClass('hidden');
            _this.$showOnSubmitElements.addClass('hidden');
        });
    };

    AvailabilityForm.prototype.updateInfoBars = function (disable) {
        if (disable) {
            $.each(availabilityInfoBars, function () {
                $(this).text('');
                $(this).addClass('hidden');
            });
            $.each(availabilityDateViews, function () {
                if ($(this).data('default-label')) {
                    $(this).text($(this).data('default-label'));
                } else {
                    $(this).text('');
                }
            });

            return;
        }

        var infos =[];
        var $serviceInput = this.$inputs.filter('.js-availability-form__room-service');
        var $dateFromInput = this.$inputs.filter('.js-availability-form__from');
        var $dateToInput = this.$inputs.filter('.js-availability-form__to');

        if (!window['_translations']) {
            console.error('JS Object _translations is not set.');
            return '';
        }

        var roomParam =  this.$inputs.filter('.js-availability-form__room-info').val();
        var roomString = roomParam.split('-')[1];
        var personCount = roomString.split(/[\|,]/).length;
        var onlyAdults = !roomString.replace(new RegExp('(,|18)*', 'g'), '').length;
        var roomCount = roomString.split('|').length;
        var roomType = roomParam.split('-')[0];

        infos.push(personCount + ' ' + _translations[(onlyAdults ? 'adult': 'person') + '.' + (personCount == 1 ? 'singular': 'plural')]);
        infos.push(roomCount + ' ' + _translations['room-type-' + roomType + '.' + (roomCount == 1 ? 'singular': 'plural')]);

        infos.push($serviceInput.find('option:selected').text());

        $.each(availabilityInfoBars, function () {
            if (infos.length) {
                $(this).text(infos.join(', '));
                $(this).removeClass('hidden');
            } else {
                $(this).text('');
                $(this).addClass('hidden');
            }
        });

        /*update button*/
        if (new Date()['toLocaleDateString']) {
            if ($dateFromInput.val() && $dateToInput.val()) {
                var dateText = [
                    new Date(+$dateFromInput.val()).toLocaleDateString(_cfg.lang, {month: '2-digit', day: '2-digit'}),
                    new Date(+$dateToInput.val()).toLocaleDateString(_cfg.lang, {month: '2-digit', day: '2-digit'})
                ].join(' - ');

                $.each(availabilityDateViews, function () {
                    $(this).text(dateText);
                });
            }
        }
    };

    return function ($scope)  {
        $scope.find('.js-availability-form').each(function () {
            availabilityForms.push(new AvailabilityForm($(this)));
        });

        $scope.find('.js-availability-info-bar').each(function () {
            availabilityInfoBars.push($(this));
        });

        $scope.find('.js-availability-date-view').each(function () {
            availabilityDateViews.push($(this));
        });
    }
}