import {loadTooltipBundle} from "./tooltipBundle";

export function initInScope($scope) {
    let $body = $('body');
    let $langContainer = $scope.find('.js-header-lang');
    let $langToggle = $scope.find('.js-header-lang__toggle');
    let $searchBar = $scope.find('.js-header-search');
    let $searchToggle = $scope.find('.js-header-search__toggle');

    function closeDropdown() {
        console.log('closeDropdown');
        $('.js-drop-down__menu, .js-drop-down').removeClass('show');
        $body.removeClass('nav-open toggled-overlay');
    }

    document.addEventListener('keydown', event => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeDropdown();
            $('.js-sticky-overlay').removeClass('active');
            $('.js-sticky-overlay__btn').removeClass('active');
            $searchBar.removeClass('active');
            $langContainer.removeClass('active');
        }
    });


    /* quickfinder overlay */
    $scope.find('.js-quickfinder__btn').on('click', function (evt) {
        evt.preventDefault();
        let quickfinder = $scope.find('.js-quickfinder');

        if ($(quickfinder).hasClass('active')) {
            $(quickfinder).removeClass('active');
            $(this).removeClass('active');

            sessionStorage.setItem('quickfinder' , 'closed');
        } else {
            $(quickfinder).addClass('active');
            $(this).addClass('active');
            sessionStorage.removeItem('quickfinder');
        }
    });
    $scope.find('.js-quickfinder-set-storage').on('click', function (evt) {
        sessionStorage.setItem('quickfinder' , 'closed');   
    });


    /* show sticky btns */
    let stickyBtnsToggle = $scope.find('.js-show-sticky-btns'),
        stickyBtnWrapper = stickyBtnsToggle.closest('.js-sticky-btns__wrapper');

    stickyBtnsToggle.on('click', function (evt) {
        evt.preventDefault();

        let $toolTipOpenTitle = $(this).attr("data-open-title");
        let $toolTipCloseTitle = $(this).attr("data-close-title");

        loadTooltipBundle().then(() => {
            $('[data-toggle="tooltip"]').tooltip("hide");
        });

        if ($(this).hasClass('opened')) {
            $(this).removeClass('opened');
            $(this).attr('data-original-title', $toolTipOpenTitle);

            stickyBtnWrapper.removeClass('opened');

        } else {
            //$(stickyBtns).removeAttr('hidden');
            $(this).addClass('opened');
            $(this).attr('data-original-title', $toolTipCloseTitle);

            stickyBtnWrapper.addClass('opened');
        }

    });

    /* fade out after page load */
    if($('.js-sticky-btns__wrapper').length) {
        if (!sessionStorage.getItem('stickyAnimation')) {
            window.setTimeout(
                function(){
                    // stickyBtnWrapper.addClass('opened');
                    // stickyBtnsToggle.addClass('opened');
                    sessionStorage.setItem('stickyAnimation' , 'finished');
                }, 1500);
            /*window.setTimeout(
                function(){
                    stickyBtnWrapper.removeClass('opened');
                    stickyBtnsToggle.removeClass('opened');
                }, 4500);*/
        }
    }
    if (sessionStorage.getItem('stickyAnimation')) {
        if (!sessionStorage.getItem('tripplanerAnimation')) {

            let $tripplanerToolTip = $('.js-toggle-tripplaner-tooltip');

            loadTooltipBundle().then(() => {
                window.setTimeout(
                    function () {
                        sessionStorage.setItem('tripplanerAnimation', 'finished');
                        $tripplanerToolTip.tooltip("show");
                    }, 2500);
                window.setTimeout(
                    function () {
                        $tripplanerToolTip.tooltip("hide");
                    }, 4500);
            });
        }
    }

    /* OVERLAYS */
    $scope.find('.js-sticky-overlay__btn').on('click', function (evt) {
        evt.preventDefault();
        closeDropdown();
        $searchBar.removeClass('active');
        $langContainer.removeClass('active');

        let overlay = $(this).attr('href');

        if ($(this).hasClass('active')) {
            /* close current overlay */
            $(overlay).removeClass('active');
            $(this).removeClass('is-active active');
            addHTMLScrollLock(false);

        } else {
            /* close all overlays */
            $('.js-sticky-overlay').removeClass('active');
            $('.js-sticky-overlay__btn').removeClass('active');

            /* open current overlay */
            $(overlay).addClass('active');
            $(this).addClass('is-active active');
            if(!$(this).data('scroll-locked')) {
                addHTMLScrollLock();
            }
        }
    });


    $scope.find('a[href="#newsletterAnmeldung-global"]').on('click', function (evt) {
        evt.preventDefault();
        $('#newsletterAnmeldung-global').addClass('active');
        addHTMLScrollLock();
    });


    $scope.find('.js-sticky-overlay__close').on('click', function (evt) {
        evt.preventDefault();

        /* close all overlays */
        $('.js-sticky-overlay').removeClass('active');
        $('.js-sticky-overlay__btn').removeClass('active');
        addHTMLScrollLock(false);

    });

    function addHTMLScrollLock(bool = true) {
        $body = $('body');
        if(bool) {
            if (matchMedia('(max-width: 767px)').matches) {
                $body.css('top', `-${document.documentElement.scrollTop || document.body.scrollTop}px`);

                // hint: data-attr as a workaround for iOs
                $body.data('top', `-${document.documentElement.scrollTop || document.body.scrollTop}px`);
                $('html').addClass('locked');
            }
        }
        else {
            if (matchMedia('(max-width: 767px)').matches) {
                let scrollPosition = +$body.data('top').replace('-', '').replace('px', '');
                $body.css('top', '');
                $body.data('top', '');
                $('html').removeClass('locked');

                window.scrollTo(0, scrollPosition);
            }
        }
    }

    $scope.find('.js-sticky-overlay__submit').on('click', function (evt) {
        $('.js-sticky-overlay').removeClass('active');
        $('.js-sticky-overlay__btn').removeClass('active');
    });


    /* SEARCH + LANG */

    $langToggle.on('click', function (e) {
        e.preventDefault();
        closeDropdown();
        $searchBar.removeClass('active');
        $('.js-sticky-overlay').removeClass('active');
        $('.js-sticky-overlay__btn').removeClass('active');

        if ($langContainer.hasClass('active')) {
            $langContainer.removeClass('active');
            $body.removeClass('toggled-overlay');
        } else {
            $langContainer.addClass('active');
            $body.addClass('toggled-overlay');
        }
    });

    function closeSearch() {
        $searchBar.removeClass('active');
        $body.removeClass('nav-open');
    }

    $searchToggle.on('click', function (e) {
        e.preventDefault();
        $langContainer.removeClass('active');
        $('.js-sticky-overlay').removeClass('active');
        $('.js-sticky-overlay__btn').removeClass('active');

        if (!$body.hasClass('is-search-open')) {
            closeDropdown();
            $searchBar.addClass('active');
            $searchBar.find('input').focus();
            if(matchMedia('(min-width: 768px)').matches){
                $('body').addClass('nav-open');
                $('body').addClass('is-search-open');
            }
            $searchBar.trigger('opened.search');

        } else {
            $('body').removeClass('is-search-open');
            closeSearch();
            closeDropdown();
        }
    });

    $(document).click(function(event) {
        if(!$(event.target).closest('header').length) {
            if($body.hasClass('toggled-overlay')) {
                closeSearch();
            }
        }
    });

    $(window).scroll(function() {
        closeSearch();
    });
}
