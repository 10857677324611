"use strict";

// define nav height here, so the element scrolls below the nav
const navHeight = 125;

export function initInScope ($scope) {
    $scope.find('.js-scroll-to').on('click', function (evt) {
        evt.preventDefault();
        let targetSelector = $(this).data('scroll-target') || $(this).attr('href');
        let $target = $(targetSelector);

        switch ($(this).data('toggle')) {
            default:
                scrollTo($target, null, true);
        }
    });
}


export function scrollTo ($target, callback, doNotFocusAfterScroll) {
    if (!$target || !$target.length) {
        console.warn("Can't scroll to '$target'. Target element not found");
        return;
    }

    let newTop = $target.offset().top - navHeight;
    //let duration = Math.abs(newTop - window.pageYOffset) / 8 + 500;
    let duration = 750;

    window.scrollTo(0, newTop);

    if (!doNotFocusAfterScroll) {
        focus($target);
    }

    if (callback) {
        callback();
    }
}

function focus ($target) {
    // todo: this needs some fixing -> focus() centers the element in the viewport, so it changes the scrolling position
    if (typeof $target.attr('tabindex') == 'undefined') {
        $target.addClass('hide-focus');
        $target.attr('tabindex', '-1');
    }
    $target.focus();
}
