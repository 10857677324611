"use strict";

import {add} from '@elements/scroll-animations';

export function initInScope($scope) {
    add(
        $scope.find('.js-animate'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 8 * 7;
    const end = window.innerHeight / 1.5;

    switch ($(element).data('animation-type')) {
        case 'transform':
            return {
                transform: Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),100),0) * 8
            };
        default:
            return {
                opacity: 1 - Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),1),0)
            }
    }
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    switch ($(element).data('animation-type')) {
        case 'transform':
            element.style.transform = 'translate3d(0, -'+ progress.transform +'%, 0)';
            break;
        default:
            element.style.opacity = progress.opacity;
    }
}