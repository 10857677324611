export function init() {
    let $baseElement = $('.js-search-restriction');
    let $text = $baseElement.find('.js-search-restriction__text');
    let $toggle = $baseElement.find('.js-search-restriction__checkbox');
    let $valueInput = $baseElement.find('.js-search-restriction__value');

    $(document.body).on('opened.dropdown', function (evt, data) {
        $text.text(data.text);
        $valueInput.val(data.id);
        $baseElement.attr('hidden', null);
    }).on('closed.dropdown nav-closed opened.search', function () {
        $baseElement.attr('hidden', 'hidden');
        $valueInput.val('');
    }).on('opened.search', function () {
        $toggle.prop('checked', false);
    });
}