import {loadTooltipBundle} from "./tooltipBundle";

export function initInScope($scope) {
    let $tooltips = $scope.find('[data-toggle="tooltip"]');

    if ($tooltips.length < 1){
        return;
    }

    loadTooltipBundle().then(function () {
        $tooltips.tooltip();
    });
}