export function initInScope ($scope) {
    $scope.find('.js-navbar-toggler').on('click', function (evt){
        evt.preventDefault();
        if($('body').hasClass('nav-open')) {
            $('body').removeClass('nav-open');
        } else {
            $('body').addClass('nav-open');
        }
    });

    $scope.find('.js-close-nav').on('click', function (evt){
        evt.preventDefault();
        $('body')
            .removeClass('nav-open')
            .removeClass('is-search-open');
        $('.js-drop-down__menu, .js-drop-down').removeClass('show').trigger('nav-closed');
    });

    $scope.find('.js-toggle-subnav').on('click', function (evt) {
        evt.preventDefault();

        let navItem = $(this).closest('li');

        if(navItem.hasClass('show-level')) {
            navItem.removeClass('show-level');
        } else {
            $(this).closest('.js-dd-container').find('.js-dd-list>li').removeClass('show-level');
            navItem.addClass('show-level');
        }

        $(this).closest('.js-dd-container').find('.js-dd-list>li>a').removeClass('active-svg');
    });



    /* seasons tabs */
    let $seasonToggle = $scope.find('.js-dd-seasons__toggler');
    let $seasonToggleWinter = $scope.find('.js-hide-winter-highlights');
    let $seasonToggleSummer = $scope.find('.js-hide-summer-highlights');

    $seasonToggle.on('click', function (evt) {
        if($(this).hasClass('active')) {
            evt.stopImmediatePropagation();
            let $activeTab = $(this).attr('href');

            $($activeTab).removeClass('active show');
            $(this).removeClass('active show');
        }
        $seasonToggleWinter.parent().removeClass('show-level');
        $seasonToggleSummer.parent().removeClass('show-level');
    });


    $seasonToggleWinter.on('click', function (evt) {
        $('#erlebenWinter, #erlebenSummer').removeClass('active show');
        $seasonToggle.removeClass('active');
    });
    $seasonToggleSummer.on('click', function (evt) {
        $('#erlebenWinter, #erlebenSummer').removeClass('active show');
        $seasonToggle.removeClass('active');
    });


    /*$('.js-navbar-toggler').on('click', function (evt) {
        evt.preventDefault();

        let body = $('body');

        if(body.hasClass('nav-opened')) {
            $('body').removeClass('nav-opened');
        } else {
            $('body').addClass('nav-opened');
        }
    });*/

}
