"use strict";

export function initInScope ($scope) {
    if(location.hash) {
        if(location.hash === "#nopopup") {
            sessionStorage.setItem('nlPopup','closed');
        }
    }

    //if (sessionStorage.getItem('tripplanerAnimation')) {
        if (!sessionStorage.getItem('nlPopup')) {
            window.setTimeout(
                function(){
                    $('.js-nl-popup').addClass('active');
                }, 5000);
        }
    //}

    $scope.find('.js-nl-popup__close').on('click', function (ev) {
        ev.preventDefault();
        $('.js-nl-popup').removeClass('active');
        sessionStorage.setItem('nlPopup','closed');
    });
}
