"use strict";

export function initInScope($scope) {
    let $body = $('body');
    let $dropDowns = $scope.find('.js-drop-down');
    let $dropDownsToggles = $scope.find('.js-drop-down__toggle');

    $dropDownsToggles.on('click', function (evt) {
        evt.preventDefault();
        $scope.find('.js-header-lang, .js-header-search').removeClass('active');

        let $dropDown = $(this).closest('.js-drop-down');
        if ($dropDown.hasClass('show')) {
            closeDropDown($dropDown);
            $body.off('click');
        } else {
            closeDropDown($dropDowns);
            openDropDown($dropDown);
            $body.on('click');
        }
    });


    /* close dropdown on scroll */
    if(matchMedia('(min-width: 768px)').matches){
        $(window).scroll(function(){
            if($body.hasClass('nav-open')) {
                $('.js-drop-down').removeClass('show');
                $('.js-drop-down__menu').removeClass('show');
                $('body').removeClass('nav-open');
            }
        });
    }
}

function openDropDown($dropDown) {
    let toggle = $dropDown.find('.js-drop-down__toggle');
    $dropDown.addClass('show').find('.js-drop-down__menu').addClass('show');
    if(matchMedia('(min-width: 768px)').matches){
        $('body').addClass('nav-open').removeClass('is-search-open');
        $dropDown.trigger('opened.dropdown', {
            text: toggle.text().trim(),
            id: $dropDown.data('id'),
        });
    }
}

function closeDropDown($dropDown) {
    $dropDown.removeClass('show').find('.js-drop-down__menu').removeClass('show');
    if(matchMedia('(min-width: 768px)').matches){
        $('body').removeClass('nav-open');
        $dropDown.trigger('closed.dropdown');
    }
}