"use strict";

import * as tripplanerItemsStore from './tripplaner/tripplaner-items-store';
import * as subscribers from './subscribe';
import * as loginState from './login-state';

export function initInScope ($scope) {
    let $loginModal = $scope.find('.js-login-overlay'),
        $loginModalDismiss = $loginModal.find('.js-login-overlay__dismiss input');

    $loginModalDismiss.on('change', function () {
        if ($(this).is(':checked')) {
            sessionStorage.setItem('dismissedLoginForm', true)
        } else {
            sessionStorage.removeItem('dismissedLoginForm');
        }
    });

    let prevTripPlanerItemCount = tripplanerItemsStore.getItems().length;
    subscribers.subscribe('data-tripPlanerItems-change', function () {
        if (!loginState.isLoggedIn() && !sessionStorage.getItem('dismissedLoginForm') && tripplanerItemsStore.getItems().length > prevTripPlanerItemCount) {

            $('.js-sticky-overlay').removeClass('active');
            $('.js-sticky-overlay__btn').removeClass('active');
            
            $loginModalDismiss.addClass('hidden');
            $loginModal.addClass('active');

            //todo
            /* load fb sdk because: fb login*/
            // app.loadFacebookSDK();
        }

        prevTripPlanerItemCount = tripplanerItemsStore.getItems().length;
    });

    subscribers.subscribe('data-loginStatus-login', function () {
        if (loginState.isLoggedIn()) {
            $loginModal.removeClass('active');
        }
    });
}
