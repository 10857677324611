"use strict";

import * as loginState from './login-state';
import {loadTooltipBundle} from "./tooltipBundle";
import {loadFacebookSDK} from "js/scripts/load-facebook-sdk";


export function initInScope($scope) {
    if (localStorage.getItem('smglogin')) {
        $('.js-login-state').addClass('logged-in').attr('href', '#overlayLogout');
        $('.js-login-state').removeClass('active');
        loadTooltipBundle().then(() => {
            $('.js-login-state').tooltip('dispose');
        });
        $('#overlayLogin').removeClass('active');
    } else {
        //console.log('not logged in');
    }


    /* login */
    $scope.find('.js-smg-login-form').on('submit', function (evt) {
        evt.preventDefault();
        let $form = $(this);
        let $errorContainer = $form.find('.js-smg-login-form__errors');
        $errorContainer.attr('hidden', 'hidden');

        loginState.login($form.find('.js-smg-login-form__email').val(), $form.find('.js-smg-login-form__pwd').val())
            .then(function (response) {
                $errorContainer.attr('hidden', 'hidden');
                $('.js-login-state').addClass('logged-in').attr('href', '#overlayLogout');
                $('.js-login-state').removeClass('active');
                loadTooltipBundle().then(() => {
                    $('.js-login-state').tooltip('dispose');
                });
                $('#overlayLogin').removeClass('active');
            })
            .catch(function () {
                $errorContainer.removeAttr('hidden');
            });
    });


    /* logout */
    $scope.find('.js-smg-logout-form').on('submit', function (evt) {
        loginState.logout();
    });


    /* register */
    $scope.find('.js-smg-register-form__form').on('submit', function (evt) {
        let $form = $(this);
        let $formError = $form.find('.js-smg-register-form__errors');
        evt.preventDefault();

        if (!$form.parsley || $form.parsley().validate()) {
            $formError.attr('hidden', 'hidden');

            let request = fetch('//service.suedtirol.info/appservice/api/v1/Account/Register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(new FormData($form[0]))
            }).then(response => response.json());

            request.catch(function (result) {
                if (result && result.error) {
                    $formError.text(result.error);
                    $formError.removeAttr('hidden');
                }
            });

            request.then(function (result) {
                if (result && result.error) {
                    $formError.text(result.error);
                    $formError.removeAttr('hidden');
                } else {
                    loginState.login($form[0].email.value, $form[0].password.value);
                    $('.js-login-state').addClass('logged-in').attr('href', '#overlayLogout');    
                }
                
                //let $resgisterSection = $form.closest('.js-smg-register-form');
                //let $successMessage = $resgisterSection.find('.js-smg-register-form__success').clone().removeAttr('hidden');
                //$resgisterSection.empty();
                //$resgisterSection.append($successMessage);

                $('#overlayRegister').removeClass('active');
            });
        }
    });

    /* reset */
    $scope.find('.js-smg-reset-pw-form').on('submit', function (evt) {
        let $form = $(this);
        let $errorContainer = $form.find('.js-smg-reset-pw-form__error');
        evt.preventDefault();
        $errorContainer.attr('hidden', 'hidden');

        return fetch('//service.suedtirol.info/appservice/api/v1/Account/ResetPassword?' + $form.serialize(), {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
        .then(function (result) {
            if (result && result.error) {
                $errorContainer.text(result.error);
                $errorContainer.removeAttr('hidden');
            } else {
                $form.collapse('hide');
                $('.js-smg-reset-pw-form__success').collapse('show');
            }
        }).catch(function (result) {
            if (result && result.error) {
                $errorContainer.text(response.error);
                $errorContainer.removeAttr('hidden');
            }
        });
    });


    /* new pwd */
    $scope.find('.js-smg-new-password-form').on('submit', function (evt) {
        let $form = $(this);
        let $errorContainer = $form.find('.js-smg-new-password-form__error');
        evt.preventDefault();
        $errorContainer.attr('hidden', 'hidden');
        if ($form.parsley().validate()) {
            fetch('//service.suedtirol.info/appservice/api/v1/Account/SetNewPassword', {
                method: 'POST',
                body: new URLSearchParams(new FormData($form[0])),
                headers: {
                    "Authorization": "Bearer " + $form[0].token.value
                },
                mode: 'cors'
            })
                .then(response => response.json())
                .then(function (response) {
                    console.log('response', response);
                    if (response && response.error) {
                        $errorContainer.text(response.error);
                        $errorContainer.removeAttr('hidden');

                    } else {
                        loginState.login($form[0].email.value, $form[0]['new_password'].value).then(function () {
                            let url = $form.data('success-redirect-url');
                            window.location.href = url;
                        });
                    }
                }).catch(function (response) {
                if (response && response.error) {
                    $errorContainer.text(response.error);
                    $errorContainer.removeAttr('hidden');
                }
            });
        }
    });


    /* fb-login */
    $('.js-smg-login-form__facebook').on('click', function () {
        loadFacebookSDK(function () { /*should have been loaded before, just in case something went wrong*/
            loginState.facebookLogin();
        });
    });

    /* apple-login */
    $('.js-smg-login-form__apple').on('click', function () {
        let state = Math.floor(Math.random() * 10000000);
        try {
            localStorage.setItem('apple-login-lang', _config.lang);
            localStorage.setItem('apple-login-state', state.toString());
        } catch (e) {}
        window.location.href = `https://appleid.apple.com/auth/authorize?client_id=info.suedtirol.it.dimension.suedtirol&redirect_uri=https://suedtirol.info/apple/Callback&response_type=code%20id_token&scope=name%20email&response_mode=form_post&state=${state}`
    });
}
