let app = window.app || {};
window.app = app;
window.app.modules =  app.modules || {};

import initModulesInScope from "../scripts/initModulesInScope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' & \'\'$.getScript\' are going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

$.getScript = function (...rest) {
    let result = $.loadScript(...rest);
    result.done = result.then;
    result.fail = result.catch;
    return result;
};

// jquery animate fallback
$.fn.animate = $.fn.animate || function (properties, ...rest) {
    let element = this;
    let duration = rest.filter(x => typeof x === "number")[0] || 0;
    if (duration) {
        $(element).css({
            'transition': `all ${duration}ms ease-out`
        });

        $(element).offset(); // force repaint
    }

    $(this).css(properties);

    let callback = rest.filter(x => typeof x === "function")[0];
    if (callback) {
        let timeout = setTimeout(function () {
            $(element).css({
                'transition': ''
            });
            callback.call(element);
            clearTimeout(timeout);
        }, duration);

    }
};


/**********************************
 *
 *      Bootstrap Imports
 *
 * ********************************/

import * as tooltip from '../scripts/tooltip';
app.modules.tooltip = tooltip.initInScope;


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as ajaxInclude from '../scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as openQuickfinder from '../scripts/openQuickfinder';
app.modules.openQuickfinder = openQuickfinder.initInScope;

/* es5 script from old suedtirol */
import * as availabilityForm from '../scripts/availabilityForm';
app.modules.availabilityForm = availabilityForm.initInScope;

import * as parsley from '../scripts/parsley';
app.modules.parsley = parsley.initInScope;

import * as rangeDatePicker from '../scripts/rangeDatePicker';
app.modules.rangeDatePicker = rangeDatePicker.initInScope;

import {init as initCookiePermissions} from "../scripts/cookie-permissions";
initCookiePermissions();

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.createInitInScope(undefined, {
    preventNative: true,
    offset: matchMedia('(max-width: 767px)').matches
        ? 0
        : window.innerHeight / 2
});

import * as toggleNav from '../scripts/toggleNav';
app.modules.toggleNav = toggleNav.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as dropDown from '../scripts/dropDown';
app.modules.dropDown = dropDown.initInScope;

import * as headerOverlays from '../scripts/headerOverlays';
app.modules.headerOverlays = headerOverlays.initInScope;

import * as scrollTo from '../scripts/scrollTo';
app.modules.scrollTo = scrollTo.initInScope;

import * as affix from '../scripts/affix';
app.modules.affix = affix.initInScope;

import * as slider from '../scripts/slider';
app.modules.slider = slider.initInScope;

import * as shariff from '../scripts/shariff';
app.shariff = shariff.init;

import * as tripPlanerAdd from '../scripts/tripplaner/tripplaner-add';
app.modules.tripPlanerAdd = tripPlanerAdd.initInScope;

import * as tripPlanerCounter from '../scripts/tripplaner/tripplaner-counter';
app.modules.tripPlanerCounter = tripPlanerCounter.initInScope;

import * as animateElements from '../scripts/animateElements';
app.modules.animateElements = animateElements.initInScope;

import * as regionSelect from '../scripts/regionSelect';
app.modules.regionSelect = regionSelect.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as gtmTracking from '../scripts/gtmTracking';
app.modules.gtmTracking = gtmTracking.initInScope;

import * as gtmTrackingPromoClick from '../scripts/gtmTrackingPromoClick';
app.modules.gtmTrackingPromoClick = gtmTrackingPromoClick.initInScope;

import * as gtmTrackingPromoImpression from '../scripts/gtmTrackingPromoImpression';
app.modules.gtmTrackingPromoImpression = gtmTrackingPromoImpression.initInScope;

import * as nlPopup from '../scripts/nlPopup';
app.modules.nlPopup = nlPopup.initInScope;

import * as smgForms from '../scripts/smg-forms';
app.modules.smgForms = smgForms.initInScope;

import {appleLogin} from "../scripts/login-state";
app.appleLogin = appleLogin;

import * as hoverPrefetch from '../scripts/hoverPrefetch';
app.modules.hoverPrefetch = hoverPrefetch.initInScope;

import * as searchRestriction from '../scripts/search-restriction';
app.searchRestriction = searchRestriction.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as typeahead from '../scripts/typeahead';
app.modules.typeahead = typeahead.initInScope;

import * as loginOverlay from '../scripts/login-overlay';
app.modules.loginOverlay = loginOverlay.initInScope;