"use strict";

import * as tripplanerItemsStore from './tripplaner-items-store';
import * as subscribers from '../subscribe';

export function initInScope($scope, resetOnlyState) {
    console.log('trip planer');
    subscribers.subscribe('data-tripPlanerItems-change', function () {
        let items = tripplanerItemsStore.getItems();

        $('.js-trip-planer-add').each(function () {
            let $this = $(this);
            let type = $this.data('typ') || 'doc';

            if (tripplanerItemsStore.getItem($this.data('id'), type)) {
                $this.addClass('is-added');
            } else {
                $this.removeClass('is-added');
            }
        });
    });

    let tripPlanerAdd = $('body').find('.js-trip-planer-add');
    if (!resetOnlyState) {
        tripPlanerAdd.on('click', function (evt) {

            console.log('add');

            evt.preventDefault();
            evt.stopImmediatePropagation();
            if ($(this).hasClass('is-added')) {
                subscribers.publish('ui-tripPlanerItems-remove', {
                    objectid: $(this).data('id'),
                    type: $(this).data('typ') || 'doc'
                });
            } else {
                subscribers.publish('ui-tripPlanerItems-add', {
                    objectid: $(this).data('id'),
                    type: $(this).data('typ') || 'doc'
                });

                import('../tooltipBundle').then(() => {
                    $('.js-trip-planer-add').tooltip("hide");

                    let $tripplanerToolTip = $('.js-toggle-tripplaner-tooltip');
                    let $toolTipAddTitle = $tripplanerToolTip.attr("data-add-title");
                    let $toolTipDefaultTitle = $tripplanerToolTip.attr("data-default-title");

                    $tripplanerToolTip.attr("data-original-title", $toolTipAddTitle);
                    $tripplanerToolTip.tooltip("show");

                    window.setTimeout(
                        function () {
                            $tripplanerToolTip.tooltip("hide");
                            $tripplanerToolTip.attr("data-original-title", $toolTipDefaultTitle);
                        }, 2500);

                });
            }


            $(this).blur();
        }).on('click', function (evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
        }).on('IdChanged', function () {
            $(this).attr('data-id', $(this).data('id'));
            $(this).attr('data-typ', $(this).data('typ'));

            let type = $(this).data('typ') || 'doc';

            if (tripplanerItemsStore.isInList($(this).data('id'), type)) {
                $(this).addClass('is-added');
            } else {
                $(this).removeClass('is-added');
            }
        });
    }

    tripPlanerAdd.each(function () {
        let id = $(this).data('id');
        let type = $(this).data('typ') || 'doc';
        let $this = $(this);

        if (tripplanerItemsStore.getItem(id, type)) {
            $this.addClass('is-added');
        }
    });
}