export function initInScope ($scope) {

    let $promoTeaser = $scope.find('.js-gtm-tracking-promoteaser');

    $promoTeaser.each(function (){
        $(this).on('click', function (evt) {
            if (evt.originalEvent && evt.originalEvent.isTrusted) {
                // make sure to only trigger once when the user clicks; otherwise isClickable would trigger this block twice
                console.log("promo teaser clicked");
                window.dataLayer.push({
                    "event": "promoClick",
                    "ecommerce": {
                        "promoClick": {
                            "promotions": [{
                                "name": $(this).data('promoname')
                            }]
                        }
                    }
                });
            }
        })
    });
}