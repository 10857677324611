"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";

export function init () {
    let $shariff = $('.js-shariff');

    onEnterViewPort($shariff, function () {
        import('shariff').then(function () {
            new Shariff($shariff, {
                //do stuff
            });
        });
    })
}