"use strict";

import * as loginState from '../login-state';
import * as subscribers from '../subscribe';

let items = []; /*saved items from api*/
let itemsWithDetail = [];
let showDetail =  _config.tripPlanerData || (_config._preload && _config._preload.tripPlanerData);

export function getItems() {
    return items;
}

export function getItem(id, type) {
    return $.grep(items, function (item, index) {
        return item.objectid.toString().toLowerCase() == id.toString().toLowerCase() && item.type == type;
    })[0];
}

export function getItemsWithDetail () {
    return itemsWithDetail;
}

export function getItemWithDetail(id, type) {
    return $.grep(itemsWithDetail, function (item, index) {
        return item.objectid.toString().toLowerCase() == id.toString().toLowerCase() && item.type == type;
    })[0];
}

let getItemsFromAPIRequest;
function loadItemsFromAPI() {
    if (getItemsFromAPIRequest) {
        return getItemsFromAPIRequest;
    }

    getItemsFromAPIRequest = jQuery.Deferred();

    fetch('//service.suedtirol.info/appservice/api/v1/Tripplaner/GetTripplanerIds', {
        headers: {
            "Authorization": "Bearer " + loginState.getBearerToken()
        }
    })
    .then(response => response.json())
    .then(function (response) {
        if (response && Array.isArray(response)) {
            items = response;
            subscribers.publish('data-tripPlanerItems-change');

            if (showDetail){
                loadItemDetails();
            }
        } else {
            console.error('error during loading trip planer items');
        }
        getItemsFromAPIRequest.resolve();
    }).catch(function (response) {
        /*the request fails if there is empty list*/
        items = [];
        getItemsFromAPIRequest.resolve();
    });

    return getItemsFromAPIRequest;
}

/*loads detail info of all missing items*/
function loadItemDetails() {
    if (!_config.tripPlanerDataUrl) {
        console.error('Can not load trip planer detail infos. _config.tripPlanerDataUrl was not set');
    }

    let items = getItems();
    let itemsToLoad = $.grep(items, function (item) {
        return !getItemWithDetail(item.objectid, item.type);
    });

    let params = $.map(itemsToLoad, function (item, index) {
        return [{
            name: 'id[' + index + ']',
            value: item.objectid
        }, {
            name: 'typ[' + index + ']',
            value: item.type
        }];
    });

    if (params.length) {
        //at least one item has to be loaded
        params.push({
            name: 'lang',
            value: _config.lang
        });


        fetch(_config.tripPlanerDataUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded' 
                },
                body: new URLSearchParams(paramsArrayToObject(params))
            })
            .then(respones => respones.json())
            .then(function (response) {
                $.each(response, function (index, item) {
                    itemsWithDetail.push(item);
                });
                //addToCache(response);
                subscribers.publish('data-tripPlanerItemsDetail-change');
            })
            .catch(function (a, b, c) {
                console.error('error during loading trip planer data from ' + _config.tripPlanerDataUrl, a, b, c);
            });
    }
}

function addItem(id, type){
    if (loginState.isLoggedIn()) {
        loadItemsFromAPI().done(function () {
            if (!isInList(id, type)) {
                fetch('//service.suedtirol.info/appservice/api/v1/Tripplaner/PutTripplanerDataSingle', {
                        method: 'PUT',
                        body: new URLSearchParams({"type": type, "objectid": id}),
                        headers: {
                            "Authorization": "Bearer " + loginState.getBearerToken()
                        }
                    })
                    .then(response => response.json())
                    .then(function (response) {
                        items.push({"type": type, "objectid": id});
                        subscribers.publish('data-tripPlanerItems-change');
                    });
            }
        });
    } else {
        if (!isInList(id, type)) {
            let newItem = {
                objectid: id,
                type: type
            };

            items.push(newItem);

            localStorage.setItem('trip-planer', JSON.stringify(items));
            subscribers.publish('data-tripPlanerItems-change');
        }
    }

    if (showDetail){
        loadItemDetails();
    }
}

function addItems(newItems) {
    if (!newItems || !newItems.length) {
        return;
    }

    if (loginState.isLoggedIn()) {
        loadItemsFromAPI().done(function () {
            let itemToAddParams = [];

            $.each(newItems, function (index, item) {
                if (!isInList(item.objectid, item.type)) {
                    itemToAddParams.push({
                        "type": item.type,
                        "objectid": item.objectid
                    });
                }
            });

            if (itemToAddParams.length) {
                fetch('//service.suedtirol.info/appservice/api/v1/Tripplaner/PutTripplanerDataExtended', {
                        method: 'PUT',
                        body: new URLSearchParams({
                            "objects": itemToAddParams
                        }),
                        headers: {
                            "Authorization": "Bearer " + loginState.getBearerToken()
                        }
                    })
                    .then(response => respones.json())
                    .then(function (response) {
                        $.each(newItems, function (index, item) {
                            items.push({"type": item.type, "objectid": item.objectid});
                        });

                    subscribers.publish('data-tripPlanerItems-change');

                    if (showDetail){
                        loadItemDetails();
                    }
                }).fail(function (a,b,c) {
                    console.error(a,b,c);
                });
            }
        });
    } else {
        $.each(items, function (index, item) {
            addItem(item.objectid, item.type);
        });
    }

}

function removeItem(id, type) {
    /*remove from items*/
    $.each(items, function (index,item) {
        if (item.objectid.toString().toLowerCase() == id.toString().toLowerCase() && item.type == type) {
            items.splice(index, 1);
            subscribers.publish('data-tripPlanerItems-change');
            return false;
        }
    });

    /*remove from itemsWithDetail*/
    $.each(itemsWithDetail, function (index,item) {
        if (item.objectid.toString().toLowerCase() == id.toString().toLowerCase() && item.type == type) {
            itemsWithDetail.splice(index, 1);
            subscribers.publish('data-tripPlanerItemsDetail-change');
            return false;
        }
    });

    if (loginState.isLoggedIn()) {
        let url = "//service.suedtirol.info/appservice/api/v1/Tripplaner/DeleteTripplanerDataSingle?";
        url += $.param({"type": type, "objectid": id });

        fetch(url, {
                method: 'DELETE',
                headers: {
                    "Authorization": "Bearer " + loginState.getBearerToken()
                }
            })
            .then(respones => respones.json())
            .then(function(response) {});
    } else {
        localStorage.setItem('trip-planer', JSON.stringify(items));
    }
}

export function isInList (id, type) {
    let alreadyInList = false;
    let list = getItems();

    $.each(list, function (index,item) {
        if (item.objectid.toString().toLowerCase() == id.toString().toLowerCase() && item.type == type) {
            alreadyInList = true;
        }
    });

    return alreadyInList;
}

function clearList () {
    items = [];
    itemsWithDetail = [];
    getItemsFromAPIRequest = null;

    subscribers.publish('data-tripPlanerItems-change');
    subscribers.publish('data-tripPlanerItemsDetail-change');

    localStorage.setItem('trip-planer', JSON.stringify(items));
}

if (loginState.isLoggedIn()) {
    loadItemsFromAPI();
} else {
    items = JSON.parse(localStorage.getItem('trip-planer')) || [];
}

if (showDetail) {
    loadItemDetails();
}

subscribers.subscribe('ui-tripPlanerItems-add', function (evt, data) {
    addItem(data.objectid, data.type);
});

subscribers.subscribe('ui-tripPlanerItems-remove', function (evt, data) {
    removeItem(data.objectid, data.type);
});

subscribers.subscribe('data-loginStatus-login', function () {
    console.log('subscribe data-loginStatus-login');
    loadItemsFromAPI().done(function () {
        //merge localstorage list to api list
        let oldList = JSON.parse(localStorage.getItem('trip-planer')) || [];
        let itemsToAdd = [];
        $.each(oldList, function (index, item) {
            if (!isInList(item.objectid, item.type)) {
                itemsToAdd.push(item);
            }
        });
        addItems(itemsToAdd);
    });
});

subscribers.subscribe('data-loginStatus-logout', function () {
    console.log('subscribe data-loginStatus-logout');
    clearList();
});

function paramsArrayToObject(arr) {
    return arr.reduce((obj, current) => {
        return {
            ...obj,
            [current.name]: current.value
        }
    }, {});
}