"use strict";

export function initInScope($scope) {
    import('slick-carousel').then(function () {
        /* hover nav and find map */
        $scope.find('.js-region-select__select').hover(function () {
            /*mouse over*/
            let $regionSelect = $(this).closest('.js-region-select');

            if ($(this).data('region-select-region')) {
                let $svgRegion = $regionSelect.find('[data-region="' + $(this).data('region-select-region') + '"]');

                if ($svgRegion && $svgRegion.length) {
                    $svgRegion.attr('class', $svgRegion.attr('class') + ' is-hovered');
                }
            }


            if ($(this).data('region-select-area')) {
                let $svgRegionArea = $regionSelect.find('[data-area="' + $(this).data('region-select-area') + '"]');

                if ($svgRegionArea && $svgRegionArea.length) {
                    $svgRegionArea.attr('class', $svgRegionArea.attr('class') + ' is-hovered');
                }
            }

        }, function () {
            /*mouse out*/
            let $regionSelect = $(this).closest('.js-region-select');

            if ($(this).data('region-select-region')) {
                let $svgRegion = $regionSelect.find('[data-region="' + $(this).data('region-select-region') + '"]');

                if ($svgRegion && $svgRegion.length) {
                    $svgRegion.attr('class', $svgRegion.attr('class').replace(' is-hovered', ''));
                }
            }


            if ($(this).data('region-select-area')) {
                let $svgRegionArea = $regionSelect.find('[data-area="' + $(this).data('region-select-area') + '"]');

                if ($svgRegionArea && $svgRegionArea.length) {
                    $svgRegionArea.attr('class', $svgRegionArea.attr('class').replace(' is-hovered', ''));
                }
            }
        });




        /* hover map and find nav */
        $scope.find('.js-region-select__svg-region').hover(function () {
            $('.js-region-select__select').removeClass('active-svg');
            $(this).closest('.js-region-select').find('[data-region-select-region="' + $(this).data('region') + '"]').addClass('active-svg');
            $(this).closest('.js-region-select').find('[data-region-select-area="' + $(this).data('area') + '"]').addClass('active-svg');
        });

        /* click on map */
        $scope.find('.js-region-select__svg-region').on('click', function () {
            let navLink = $(this).closest('.js-region-select').find('[data-region-select-region="' + $(this).data('region') + '"]').attr('href');
            window.location.href = navLink;
        });
    });
}