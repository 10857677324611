export function initInScope ($scope) {
    $scope.find('.gtm-tracking-inspireteaser').on('click', function (evt) {
        if (evt.originalEvent && evt.originalEvent.isTrusted) {
            // make sure to only trigger once when the user clicks; otherwise isClickable would trigger this block twice

            window.dataLayer.push({
                'event': 'evtInspireteaser',
                'inspireHeadline': $(this).data('headline'),
                'inspireClickUrl': $(this).data('url')
            });
        }
    })
}