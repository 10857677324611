"use strict";

export function initInScope($scope) {

    let $quickfinderBtn = $scope.find('.js-quickfinder__btn');
    let $quickfinder = $scope.find('.js-quickfinder');
    let $sliderDots = $scope.find('.overlay-dots');
    let $quickfinderBtnXs = $scope.find('.js-quickfinder__btn-xs');

    $sliderDots.addClass('overlay-dots--qf-opened');

    if (sessionStorage.getItem('quickfinder')) {
        $quickfinder.removeClass('active');
        $quickfinderBtn.removeClass('is-active active');
        $quickfinderBtnXs.removeClass('is-active active');
    }
}