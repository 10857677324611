const polyfills = [];

if (!window.HTMLPictureElement) {
    polyfills.push(import('picturefill'));
}

if (!window.fetch) {
    polyfills.push(import('whatwg-fetch'));
}

const nativeURLSearchParams = (function() {
        // #41 Fix issue in RN
        try {
            if (self.URLSearchParams && (new self.URLSearchParams('foo=bar')).get('foo') === 'bar') {
                return self.URLSearchParams;
            }
        } catch (e) {}
        return null;
    })(),
    isSupportObjectConstructor = nativeURLSearchParams && (new nativeURLSearchParams({a: 1})).toString() === 'a=1',
    // There is a bug in safari 10.1 (and earlier) that incorrectly decodes `%2B` as an empty space and not a plus.
    decodesPlusesCorrectly = nativeURLSearchParams && (new nativeURLSearchParams('s=%2B').get('s') === '+'),
    // Fix bug in Edge which cannot encode ' &' correctly
    encodesAmpersandsCorrectly = nativeURLSearchParams ? (function() {
        var ampersandTest = new nativeURLSearchParams();
        ampersandTest.append('s', ' &');
        return ampersandTest.toString() === 's=+%26';
    })() : true;

if (!(nativeURLSearchParams && isSupportObjectConstructor && decodesPlusesCorrectly && encodesAmpersandsCorrectly)) {
    polyfills.push(import('url-search-params-polyfill'));
}

let polyfillReadyPromise = Promise.all(polyfills)
    .catch((error) => {
        console.error('Failed fetching polyfills', error)
    });

export function onPolyfillsReady(cb) {
    polyfillReadyPromise.then(cb)
}