"use strict";

import * as tripplanerItemsStore from './tripplaner-items-store';
import * as subscribers from '../subscribe';

export function initInScope($scope) {
    subscribers.subscribe('data-tripPlanerItems-change', function () {
        setCounter();
    });

    setCounter();

    function setCounter() {
        let list = tripplanerItemsStore.getItems();
        let $counter = $scope.find('.js-trip-planer-counter');
        $counter.text(list.length);
        $counter.filter('.js-trip-planer-counter').each(function () {
            if((list.length) == 0) {
                $(this).attr('hidden','hidden');
            } else {
                $(this).removeAttr('hidden');
            }
        });
    }
}