"use strict";

import {loadImg} from "@elements/lazy-img";
import {onEnterViewPort} from "@elements/viewport-utils";

export function initInScope($scope) {
    let $slider =$scope.find('.js-slider');

    if ($slider.length < 1) {
        return;
    }

    import('slick-carousel').then(function () {
        $slider.each(function () {
            let $slider = $(this);
            let $settings = {
                //infinite: false,
                dots: true,
                waitForAnimate: false,
                centerMode: $slider.data('center-mode') || false,
                autoplay: $slider.data('autoplay') || false,
                centerPadding: '20px',
                initialSlide: $slider.data('initial-slide') || 0,
                slidesToShow: $slider.data('slider-items-lg') || 3,
                slidesToScroll: $slider.data('slider-items-lg') || 3,
                prevArrow: "<button type='button' class='slick-prev text-theme' aria-label='prev'></button>",
                nextArrow: "<button type='button' class='slick-next text-theme' aria-label='next'></button>",
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        centerMode: $slider.data('xs-center-mode') || false,
                        centerPadding: '20px',
                        infinite: false,
                        arrows: true,
                        swipeToSlide: true,
                        slidesToShow: $slider.data('slider-items-xs') || 1,
                        slidesToScroll: 1
                    }
                }]
            };

            function preloadBackgrounds(onlyCurrent = false) {
                let $currentHeaderImg = $slider.find('.slick-current').find('.header-img, .js-slider--preload-bg-image__img');
                let $currentHeaderImgSrc = $currentHeaderImg.attr('data-image');
                if($currentHeaderImgSrc) {
                    $currentHeaderImg.css('background-image', 'url(' + $currentHeaderImgSrc + ')');
                }

                if (!onlyCurrent) {
                    let $nextHeaderImg = $slider.find('.slick-current + .slick-slide').find('.header-img');
                    let $nextHeaderImgSrc = $nextHeaderImg.attr('data-image');
                    $nextHeaderImg.css('background-image', 'url(' + $nextHeaderImgSrc + ')');
                }
            }

            if ($slider.hasClass('js-slider--preload-bg-image')) {
                $slider.on('beforeChange', function () {
                    /* preload header bg images */
                    preloadBackgrounds();
                });
            }

            $slider.slick($settings).on('afterChange', function (event, slick, currentSlide) {
                preloadSlide(slick, currentSlide + 1);

                /* preload header bg images */
                if ($slider.hasClass('js-slider--preload-bg-image')) {
                    preloadBackgrounds();
                }
            });

            onEnterViewPort($slider, function ($slider) {
                preloadSlide($slider.slick('getSlick'), 0);
                preloadSlide($slider.slick('getSlick'), 1);
                preloadBackgrounds(true);
            });

            $slider.closest('.collapse').on('shown.bs.collapse', function () {
                $slider.css('visibility', 'visible')
                $slider.slick('unslick');
                $slider.slick($settings);
            });

            $slider.closest('.slide').find('a[data-toggle="tab"]').on('shown.bs.tab', function () {
                $slider.css('visibility', 'visible')
                $slider.slick('unslick');
                $slider.slick($settings);
            });
        });
    });
}


export function preloadSlide(slick, slideIndex) {
    import('slick-carousel').then(function () {
        let slideToPreload = slick.$slides[slideIndex];

        if (slideToPreload) {
            let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
            if ($imgToPreload && $imgToPreload.length) {
                loadImg($imgToPreload);
            }
        }
    });

}