"use strict";

let FBSDKLoaded = false;

export function loadFacebookSDK (callback) {
    if (FBSDKLoaded) {
        if (callback) {
            callback();
        }

        return;
    }

    window.fbAsyncInit = function() {
        FB.init({
            appId      : '1810463879180089',
            cookie     : true,  // enable cookies to allow the server to access
                                // the session
            xfbml      : true,  // parse social plugins on this page
            version    : 'v2.2' // use version 2.2
        });

        if (callback) {
            callback();
        }
    };

    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    FBSDKLoaded = true;
}
