export function initInScope ($scope) {

    let isImpressionMeasured = false;
    let $promoTeaser = $scope.find('.js-gtm-tracking-promoteaser');

    $(window).on('resize scroll', function() {
        $promoTeaser.each(function (){
            isImpressionMeasured = $(this).data("impression-measured");
            if (isScrolledIntoView($(this)) && !isImpressionMeasured) {
                window.dataLayer.push({
                    "event": "promoImpression",
                    "ecommerce": {
                        "promoView": {
                            "promotions": [{
                                "name": $(this).data('promoname')
                            }]
                        }
                    }
                });
                $(this).attr("data-impression-measured", true);
            }
        })
    });


    function isScrolledIntoView(elem) {
        let docViewTop = $(window).scrollTop();
        let docViewBottom = docViewTop + $(window).height();

        let elemTop = $(elem).offset().top;
        let elemBottom = elemTop + ($(elem).height() / 2 );

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
}