"use strict";

import initModulesInScope from "./initModulesInScope";
import "../libs/ajaxInclude";
import {onEnterViewPort} from "@elements/viewport-utils";

function ajaxIncludeCallback() {
    let includedContainer = $(this).next();

    if ($(this).is('[data-target]')) {
        let targetContainer = $($(this).data('target'));
        if (targetContainer.length > 0) {
            includedContainer = targetContainer.next();
        }
    }

    initModulesInScope(includedContainer);

    $(this).off('ajaxInclude', ajaxIncludeCallback);

    if (_config && _config['debug']) {
        console.warn('ajax included: ', includedContainer);
    }
}

export function initInScope($scope) {
    let ajaxIncludeElements = $scope.find('.js-ajaxInclude');

    ajaxIncludeElements.on('ajaxInclude', function () {
        if (window.picturefillCFG) {
            picturefill();
        }
    });

    let ajaxIncludeOptions = {
        device: matchMedia('(max-width: 767px)').matches ? 'mobile' : 'desktop'
    };

    ajaxIncludeElements.on('ajaxInclude', ajaxIncludeCallback);
    onEnterViewPort(ajaxIncludeElements, function (element) {
        $(element).extendAjaxInclude(ajaxIncludeOptions);
    });

    ajaxIncludeElements.filter('[data-interaction=click]').on('click', function () {
        $(this).removeAttr('data-interaction');
        $(this).extendAjaxInclude(ajaxIncludeOptions);
    });
}
