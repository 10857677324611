"use strict";

function fetchTransport(opts, onSuccess, onError) {
    fetch(opts.url, {
        type: "GET",
    })
        .then(respones => respones.json())
        .then(onSuccess)
        .catch(onError)
}

export function initInScope($scope) {
    $.getScript("/static/js/libs/typeahead.bundle.min.js").done(function() {
        $scope.find('.js-typeahead').each( function () {

            var $this = $(this);
            var bloodHoundConfig = {
                //datumTokenizer: Bloodhound.tokenizers.obj.nonword('textValue'),
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('textValue'),
                queryTokenizer: Bloodhound.tokenizers.whitespace
            };


            if ($this.data('prefetch-url')) {
                bloodHoundConfig.prefetch = {
                    url: $this.data('prefetch-url'),
                    transport: fetchTransport
                };
            }

            if ($this.data('remote-url')) {
                bloodHoundConfig.remote = {
                    url: $this.data('remote-url'),
                    wildcard: '%QUERY',
                    transport: fetchTransport
                }
            }

            // constructs the suggestion engine
            var bloodHound = new Bloodhound(bloodHoundConfig);
            bloodHound.transport = fetchTransport;

            $this.typeahead({
                    hint: true,
                    highlight: true,
                    minLength: 2
                },
                {
                    name: 'regions',
                    limit: Number.MAX_VALUE, /*workaround for https://github.com/twitter/typeahead.js/issues/1232*/
                    source: bloodHound,
                    display: 'textValue',
                    templates: {
                        suggestion: function (data) {
                            var regexp = new RegExp(data._query, 'gi');
                            if (data.textValue) {
                                return '<div class="tt-suggestion tt-selectable">' + data.textValue.replace(regexp, '<strong>$&</strong>') + '</div>'
                            }
                        }
                    }
                });

            $this.on('change', function (evt) {
                if (evt.target.value) {
                    evt.stopImmediatePropagation();
                } else {
                    $this.trigger('typeahead:select', '');
                    $this.typeahead('val', '');
                }
            });

            $this.on('programmaticChange', function (evt) {
                if (!evt.target.value) {
                    $this.trigger('typeahead:select', '');
                    $this.typeahead('val', '');
                }
            });
            if (!$this.is('.js-typeahead--website-search')) {
                $this.on('keydown', function (evt) {
                    if (evt.keyCode == 13) {
                        //console.log("E*" + $this.val());
                        evt.preventDefault();

                        /* select first */
                        $('.tt-selectable').first().click();

                        bloodHound.search($this.val(),
                            function (matches) {
                                var i;
                                if (matches.length > 0) {
                                    for (i = 0; i < matches.length; i++) {
                                        if (matches[i] == $this.val()) {
                                            $this.trigger('typeahead:select', [matches[0]]);
                                            $this.typeahead('val', matches[0].textValue);
                                        }
                                    }
                                }
                            },
                            function (matches) {
                                var i;
                                if (matches.length > 0) {
                                    for (i = 0; i < matches.length; i++) {
                                        if (matches[i] == $this.val()) {
                                            $this.trigger('typeahead:select', [matches[0]]);
                                            $this.typeahead('val', matches[0].textValue);
                                        }
                                    }
                                }
                            }
                        );
                    }
                    /* remove search icon */
                    $(this).closest('.form-control-wrapper--with-icon').find('.icon').remove();
                });
            }

            $this.on('blur', function (evt){
                //var inputVal = $('.js-typeahead__value').val();
                var inputVal = $this.val();
                if(!inputVal){
                    $this.val('');
                }
            });


            if ($this.is('.js-typeahead--acco')) {
                $this.on('typeahead:select typeahead:autocompleted', function (typeaheadObj, data) {
                    if (data['url']) {
                        location.href = data.url;
                    } else {
                        let altInput = $this.closest('.form-control-wrapper').find('.js-typeahead__value');
                        altInput.val(data.value);
                        altInput.attr('data-value',data.textValue);
                        altInput.trigger('change');
                    }
                });
            } else if ($this.is('.js-typeahead--navigate-after-change')) {
                $this.on('typeahead:select typeahead:autocompleted', function (typeaheadObj, data) {
                    if (data['url']) {
                        location.href = data.url;
                    } else {
                        location.href = data.value;
                    }
                });
            } else if ($this.is('.js-typeahead--website-search')) {
                $this.on('typeahead:select typeahead:autocompleted', function (typeaheadObj, data) {
                    $this.closest('form').submit();
                });
            } else {
                $this.on('typeahead:select typeahead:autocompleted', function (typeaheadObj, data) {
                    let altInput = $this.closest('.form-group').find('.js-typeahead__value');
                    altInput.val(data.value);
                    altInput.attr('data-value',data.textValue);
                    altInput.trigger('change');
                });
            }
        });
    });

};