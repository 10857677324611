"use strict";

import * as subscribers from './subscribe';

export function isLoggedIn() {
    let smgloginObj = JSON.parse(localStorage.getItem('smglogin'));

    return smgloginObj && smgloginObj.access_token && new Date() < new Date(smgloginObj['.expires']);
}

export function login(email, password) {
    let params = {
        "grant_type": "password",
        "username": email,
        "password": password
    };

    return fetch('//service.suedtirol.info/appservice/token', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;'
        },
        body: new URLSearchParams(params)
    })
        .then(response => response.json())
        .then(function (result) {
            if (result && result.access_token) {
                localStorage.setItem('smglogin', JSON.stringify(result));

                subscribers.publish('data-loginStatus-login');
            } else {
                return Promise.reject();
            }
        });
}

export function logout() {
    localStorage.removeItem('smglogin');

    subscribers.publish('data-loginStatus-logout');
    location.reload();
}

function facebookStatusChangeCallback(response) {
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response && response.status === 'connected') {
        // Logged into your app and Facebook.

        fetch('//service.suedtirol.info/appservice/api/v1/Account/ObtainLocalAccessToken', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "provider": "Facebook",
                "externalAccessToken": response.authResponse.accessToken
            })
        }).then(response => response.json())
            .then(function (response) {

            if (response && response.access_token) {
                localStorage.setItem('smglogin', JSON.stringify(response));

                subscribers.publish('data-loginStatus-login');

                window.location.href = _config.tripplanerUrl;
            }
        });
    }
}

export function facebookLogin() {
    FB.getLoginStatus(function (response) {
        if (response && response.status === 'connected') {
            facebookStatusChangeCallback(response);
        } else {
            FB.login(facebookStatusChangeCallback);
        }
    });
}

export function getBearerToken() {
    let smgloginObj = JSON.parse(localStorage.getItem('smglogin'));

    return smgloginObj.access_token;
}

export let appleLogin = function () {
    let payload = _config.appleLoginResult;

    if (payload) {
        let tokenRequestPayload = {
            "Provider":"Apple",
            "ExternalAccessToken": payload['id_token'],
        };
        
        if (payload.user) {
            let user = JSON.parse(payload.user);
            let lang = "de";
            let state;

            try {
                lang = localStorage.getItem('apple-login-lang');
                state = localStorage.getItem('apple-login-state');
            } catch (e) {}

            if (state && state.toString() !== payload.state.toLocaleString()) {
                console.error('States do not match', state, payload.state);
                window.location.href = _config.tripplanerUrl;
                return;
            }

            tokenRequestPayload = {
                ...tokenRequestPayload,
                "Firstname": user.name.firstName,
                "Lastname": user.name.lastName,
                "Language": lang
            };
        }
        
        fetch('//service.suedtirol.info/appservice/api/v1/Account/ObtainLocalAccessToken?suedtirolinfo=true', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(tokenRequestPayload)
        }).then(response => response.json())
            .then(function (response) {
            if (response && response.access_token) {
                localStorage.setItem('smglogin', JSON.stringify(response));

                subscribers.publish('data-loginStatus-login');
            }

            window.location.href = _config.tripplanerUrl;
        });
    }
};